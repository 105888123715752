<template>
  <div
    id="top"
    v-bind:class="[isLight ? 'background-light' : 'background-dark', 'contourPage']"
  >
    <VideoBanner
      :data-aos="fade"
      data-aos-mirror="false"
      :video="'cover.mp4'"
      :title="'Contour'"
    ></VideoBanner>

    <!-- First section  -->
    <div class="page">
      <div v-bind:class="{ background: !isLight }">
        <div class="row">
          <div class="columnLeft">
            <h1 v-bind:class="[isLight ? 'body-title-light' : 'body-title']">
              Background information
            </h1>
            <!-- <v-divider
                v-bind:class="[isLight ? 'background-dark' : 'background-light']"
              style="margin-top: 10px; margin-bottom: 10px; width: 56%"
            ></v-divider> -->
            <Description :description="projectBackground"></Description>
          </div>
          <div class="columnRight">
            <div class="section">
              <Metadata :metadata="pagedata.meta" :showdata="false"></Metadata>
            </div>

            <!-- <div class="section">
              <Technologies :technologies="pagedata.tech"></Technologies>

            </div> -->
          </div>
        </div>
      </div>
    </div>
    <!-- First section  -->
    <div :data-aos="fade" data-aos-mirror="false" class="page">
      <div v-bind:class="{ background: !isLight }">
        <div class="row">
          <div class="columnLeft">
            <h1 v-bind:class="[isLight ? 'body-title-light' : 'body-title']">Goal</h1>
            <!-- <v-divider
                v-bind:class="[isLight ? 'background-dark' : 'background-light']"
              style="margin-top: 10px; margin-bottom: 10px; width: 56%"
            ></v-divider> -->
            <p v-bind:class="[isLight ? 'body-text-light' : 'body-text']">
              How can we make a tool that enables museum exhibition designers and
              stakeholders to easily visualize their designs, as well as share, view and
              give feedback on them?
            </p>
          </div>
          <div class="columnRight">
            <!-- <div class="section">
              <Technologies :technologies="pagedata.tech"></Technologies>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <!-- second section-->
    <div :data-aos="fade" data-aos-mirror="false" class="page">
      <div v-bind:class="{ background: !isLight }">
        <v-img src="view_devices.jpg" />
      </div>
    </div>

    <div :data-aos="fade" data-aos-mirror="false" class="page">
      <div v-bind:class="{ background: !isLight }">
        <div class="row">
          <div class="columnLeft" style="padding-bottom: 0px">
            <h1 v-bind:class="[isLight ? 'body-title-light' : 'body-title']">
              Problem statement
            </h1>

            <p v-bind:class="[isLight ? 'body-text-light' : 'body-text']">
              There are different methods for visualizing exhibition designs. They each
              have their own qualities as well as drawbacks. Oftentimes, multiple methods
              are used during a project since there is no single method that encompasses
              all desired qualities. This creates extra work and can lead to communication
              problems, especially when working with external partners.
            </p>
            <br />
            <p v-bind:class="[isLight ? 'body-text-light' : 'body-text']">
              Common methods for visualizing museum exhibition designs:
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- second section-->

    <div :data-aos="fade" data-aos-mirror="false" class="page hidden-md-and-down">
      <div v-bind:class="{ background: !isLight }">
        <v-simple-table disabled dark class="problemTable transparent">
          <template v-slot:default>
            <thead>
              <tr>
                <th
                  v-for="(item, i) in tableHeadersMethods"
                  :key="i"
                  class="th-col text-left"
                >
                  <p
                    v-bind:class="[isLight ? 'body-title-light' : 'body-title']"
                    style="font-size: 132%"
                  >
                    {{ item }}
                  </p>
                </th>
              </tr>
            </thead>
            <tbody>
              <!-- method: "Maquettes", experience: "Good", presentable: "Excellent", fast: "Poor", collab: "Decent" -->
              <tr v-for="(item, i) in tableRowsMethods" :key="i">
                <td class="td-col">
                  <p v-bind:class="[isLight ? 'body-text-light' : 'body-text']">
                    {{ item.method }}
                  </p>
                </td>
                <td class="td-col">
                  <p v-bind:class="[isLight ? 'body-text-light' : 'body-text']">
                    {{ item.experience }}
                  </p>
                </td>
                <td class="td-col">
                  <p v-bind:class="[isLight ? 'body-text-light' : 'body-text']">
                    {{ item.presentable }}
                  </p>
                </td>
                <td class="td-col">
                  <p v-bind:class="[isLight ? 'body-text-light' : 'body-text']">
                    {{ item.fast }}
                  </p>
                </td>
                <td class="td-col">
                  <p v-bind:class="[isLight ? 'body-text-light' : 'body-text']">
                    {{ item.collab }}
                  </p>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
    </div>

    <div :data-aos="fade" data-aos-mirror="false" class="page">
      <div v-bind:class="{ background: !isLight }">
        <div class="row">
          <div class="columnLeft">
            <h1 v-bind:class="[isLight ? 'body-title-light' : 'body-title']">
              Discovery
            </h1>

            <p v-bind:class="[isLight ? 'body-text-light' : 'body-text']">
              We started off by conducting interviews at multiple museums with individuals
              who are involved in the creation of exhibitions. Based on this research and
              combined with our own experience, we identified several problem areas that
              are impacting the use of visualisations in the exhibition design process.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div :data-aos="fade" data-aos-mirror="false" class="page hidden-md-and-down">
      <div v-bind:class="{ background: !isLight }">
        <v-simple-table disabled dark class="problemTable transparent">
          <template v-slot:default>
            <thead>
              <tr>
                <th v-for="(item, i) in tableHeaders" :key="i" class="th-col text-left">
                  <p
                    v-bind:class="[isLight ? 'body-title-light' : 'body-title']"
                    style="font-size: 150%"
                  >
                    {{ item }}
                  </p>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, i) in tableRows" :key="i">
                <td class="td-col">
                  <p v-bind:class="[isLight ? 'body-text-light' : 'body-text']">
                    {{ item.left }}
                  </p>
                </td>
                <td class="td-col">
                  <p v-bind:class="[isLight ? 'body-text-light' : 'body-text']">
                    {{ item.center }}
                  </p>
                </td>
                <td class="td-col">
                  <p v-bind:class="[isLight ? 'body-text-light' : 'body-text']">
                    {{ item.right }}
                  </p>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
    </div>

    <div :data-aos="fade" data-aos-mirror="false" class="page">
      <div v-bind:class="{ background: !isLight }">
        <div class="row">
          <div class="columnLeft">
            <h1 v-bind:class="[isLight ? 'body-title-light' : 'body-title']">Approach</h1>
            <!-- <v-divider
                v-bind:class="[isLight ? 'background-dark' : 'background-light']"
              style="margin-top: 10px; margin-bottom: 10px; width: 44%"
            ></v-divider> -->
            <p v-bind:class="[isLight ? 'body-text-light' : 'body-text']">
              We utilized co-creation and design sprints during this project. From the
              beginning we worked together with the Rijksmuseum and other museums to make
              sure the design meets our goal. By working directly with different users, we
              obtained direct feedback on what works and doesn’t work for this new type of
              tool.
            </p>
          </div>
          <div class="columnRight">
            <div class="section">
              <v-img eager src="cocreation.jpg"></v-img>
            </div>
            <!-- <div class="section">
              <Technologies :technologies="pagedata.tech"></Technologies>
            </div> -->
          </div>
        </div>
      </div>
    </div>

    <div :data-aos="fade" data-aos-mirror="false" class="page">
      <video
        class="body-video"
        v-bind:class="{ background: !isLight }"
        :id="'video_milo_editor'"
        loop="true"
        autoplay="true"
        preload="auto"
        muted="muted"
        width="100%"
        height="auto"
      >
        <source src="contour place.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>

    <!-- third section-->

    <div :data-aos="fade" data-aos-mirror="false" class="page">
      <div v-bind:class="{ background: !isLight }">
        <div class="row">
          <div class="columnLeft">
            <h1 v-bind:class="[isLight ? 'body-title-light' : 'body-title']">
              Technology
            </h1>
            <!-- <v-divider
                v-bind:class="[isLight ? 'background-dark' : 'background-light']"
              style="margin-top: 10px; margin-bottom: 10px; width: 55%"
            ></v-divider> -->
            <p v-bind:class="[isLight ? 'body-text-light' : 'body-text']">
              First we had to figure out the best platform for our application. Since
              accessibility is one of our most important values, we opted for a web
              application. This has several benefits, like the time saved on
              cross-platform development, accessibilty and performant UI.
              <br /><br />
              There are some drawbacks to this approach, the lack of native support and
              perfomance loss are something to keep in mind. The latter is not a problem
              for our case, because our tool is not made for powerusers.
            </p>
            <!-- <Description :description="projectBackground"></Description> -->
          </div>
          <div class="columnRight">
            <div class="wrap">
              <div class="inner">
                <Technologies
                  :showHeader="false"
                  :technologies="pagedata.tech"
                ></Technologies>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div :data-aos="fade" data-aos-mirror="false" class="page">
      <div v-bind:class="{ background: !isLight }">
        <div class="row">
          <div class="columnLeft">
            <!-- <p v-html="code"></p> -->
            <h1 v-bind:class="[isLight ? 'body-title-light' : 'body-title']">
              3D Graphics
            </h1>
            <!-- <v-divider
                v-bind:class="[isLight ? 'background-dark' : 'background-light']"
              style="margin-top: 10px; margin-bottom: 10px; width: 55%"
            ></v-divider> -->
            <p v-bind:class="[isLight ? 'body-text-light' : 'body-text']">
              When it comes to 3D engines and libraries, there is a lot of choice. The two
              most comprehensive choices are Three.js and Babylon.js. After doing a lot of
              (technical) research and prototyping I chose to use the latter, because
              Babylon.js is the most complete package of the two. <br />
              Out of the box it has good camera controls and has a lot of included
              features like Blender exporters, batch loading and a great community.
            </p>
          </div>
          <!-- <div class="columnRight">
            <div class="section">
              <pre
                style="color: #d1d1d1; "
              >        <span style="color:#9999a9; ">//Create an engine and scene object</span>
        <span style="color:#e66170; font-weight:bold; ">this</span><span style="color:#d2cd86; ">.</span>_engine <span style="color:#d2cd86; ">=</span> <span style="color:#e66170; font-weight:bold; ">new</span> Babylon<span style="color:#d2cd86; ">.</span>Engine<span style="color:#d2cd86; ">(</span><span style="color:#e66170; font-weight:bold; ">this</span><span style="color:#d2cd86; ">.</span>renderCanvas<span style="color:#d2cd86; ">)</span>
        <span style="color:#e66170; font-weight:bold; ">this</span><span style="color:#d2cd86; ">.</span>Scene <span style="color:#d2cd86; ">=</span> <span style="color:#e66170; font-weight:bold; ">new</span> Babylon<span style="color:#d2cd86; ">.</span>Scene<span style="color:#d2cd86; ">(</span><span style="color:#e66170; font-weight:bold; ">this</span><span style="color:#d2cd86; ">.</span>_engine<span style="color:#d2cd86; ">,</span> <span style="color:#0f4d75; ">true</span><span style="color:#d2cd86; ">)</span><span style="color:#b060b0; ">;</span>
        <span style="color:#e66170; font-weight:bold; ">this</span><span style="color:#d2cd86; ">.</span>Scene<span style="color:#d2cd86; ">.</span>autoClearDepthAndStencil <span style="color:#d2cd86; ">=</span> <span style="color:#0f4d75; ">false</span>

        <span style="color:#9999a9; ">//Create renderlooop</span>
        <span style="color:#e66170; font-weight:bold; ">this</span><span style="color:#d2cd86; ">.</span>_engine<span style="color:#d2cd86; ">.</span>runRenderLoop<span style="color:#d2cd86; ">(</span><span style="color:#d2cd86; ">(</span><span style="color:#d2cd86; ">)</span> <span style="color:#d2cd86; ">=</span><span style="color:#d2cd86; ">&gt;</span> <span style="color:#b060b0; ">{</span>
            <span style="color:#e66170; font-weight:bold; ">this</span><span style="color:#d2cd86; ">.</span>Scene<span style="color:#d2cd86; ">.</span>render<span style="color:#d2cd86; ">(</span><span style="color:#d2cd86; ">)</span><span style="color:#b060b0; ">;</span>
        <span style="color:#b060b0; ">}</span><span style="color:#d2cd86; ">)</span><span style="color:#b060b0; ">;</span>

        <span style="color:#9999a9; ">//Tell the engine to resize when the window size changes</span>
        window<span style="color:#d2cd86; ">.</span>addEventListener<span style="color:#d2cd86; ">(</span><span style="color:#02d045; ">'</span><span style="color:#00c4c4; ">resize</span><span style="color:#02d045; ">'</span><span style="color:#d2cd86; ">,</span> <span style="color:#d2cd86; ">(</span><span style="color:#d2cd86; ">)</span> <span style="color:#d2cd86; ">=</span><span style="color:#d2cd86; ">&gt;</span> <span style="color:#b060b0; ">{</span>
            <span style="color:#e66170; font-weight:bold; ">this</span><span style="color:#d2cd86; ">.</span>_engine<span style="color:#d2cd86; ">.</span>resize<span style="color:#d2cd86; ">(</span><span style="color:#d2cd86; ">)</span><span style="color:#b060b0; ">;</span>
        <span style="color:#b060b0; ">}</span><span style="color:#d2cd86; ">)</span><span style="color:#b060b0; ">;</span>

        <span style="color:#9999a9; ">//Commit the scenedata container to the vuex store</span>
        store<span style="color:#d2cd86; ">.</span>commit<span style="color:#d2cd86; ">(</span><span style="color:#02d045; ">'</span><span style="color:#00c4c4; ">createSceneData</span><span style="color:#02d045; ">'</span><span style="color:#d2cd86; ">,</span> <span style="color:#e66170; font-weight:bold; ">this</span><span style="color:#d2cd86; ">)</span><span style="color:#b060b0; ">;</span>
</pre>
            </div>
            <div class="section"></div>
          </div> -->
        </div>
      </div>
    </div>
    <!-- second section-->

    <div :data-aos="fade" data-aos-mirror="false" class="page">
      <video
        class="body-video"
        :id="'video_milo_editor'"
        loop="true"
        autoplay="true"
        preload="auto"
        muted="muted"
        width="100%"
        height="auto"
      >
        <source src="build.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>

    <div :data-aos="fade" data-aos-mirror="false" class="page">
      <div v-bind:class="{ background: !isLight }">
        <div class="row">
          <div class="columnLeft">
            <!-- <p v-html="code"></p> -->
            <h1 v-bind:class="[isLight ? 'body-title-light' : 'body-title']">Vue.js</h1>
            <!-- <v-divider
                v-bind:class="[isLight ? 'background-dark' : 'background-light']"
              style="margin-top: 10px; margin-bottom: 10px; width: 55%"
            ></v-divider> -->
            <p v-bind:class="[isLight ? 'body-text-light' : 'body-text']">
              The Contour editor has a lot of context sensitive UI elements, such as color
              pickers for materials and coordinate drawers for meshes. To provide our app
              with a strong and intuitive UI, we chose to use Vue.js.
            </p>
            <p v-bind:class="[isLight ? 'body-text-light' : 'body-text']">
              Vue is component-based and supports conditional rendering, which allows for
              fast switching of active UI elements per context.
            </p>
            <p v-bind:class="[isLight ? 'body-text-light' : 'body-text']">
              In addition, Vue is also a good choice because of its state store, Vuex.
              Vuex stores are reactive. When Vue components retrieve state from it, they
              will reactively and efficiently update if the store's state changes. You
              cannot directly mutate the store's state. The only way to change a store's
              state is by explicitly committing mutations. This ensures every state change
              leaves a track-able record, and enables tooling that helps us better
              understand our application.
            </p>
          </div>
          <!-- <div class="columnRight">
            <div class="section">
              <pre
                class="hljs"
                style="
                  display: block;
                  overflow-x: auto;
                  padding: 0.5em;
                  background: rgb(68, 68, 68, 0);
                  color: rgb(221, 221, 221);
                "
              ><span class="xml"><span class="hljs-tag">&lt;<span class="hljs-name" style="color: rgb(221, 136, 136); font-weight: 700;">v-container</span>&gt;</span>
  <span class="hljs-tag">&lt;<span class="hljs-name" style="color: rgb(221, 136, 136); font-weight: 700;">v-data-table</span> <span class="hljs-attr">:headers</span>=<span class="hljs-string" style="color: rgb(221, 136, 136);">"headers"</span>
    <span class="hljs-attr">:items</span>=<span class="hljs-string" style="color: rgb(221, 136, 136);">"meshes"</span>
    <span class="hljs-attr">:item-class</span>=<span class="hljs-string" style="color: rgb(221, 136, 136);">"itemRowBackground"</span>
    @<span class="hljs-attr">click:row</span>=<span class="hljs-string" style="color: rgb(221, 136, 136);">"handleClick"</span>
    <span class="hljs-attr">single-select</span>
  &gt;</span>
    <span class="hljs-tag">&lt;<span class="hljs-name" style="color: rgb(221, 136, 136); font-weight: 700;">template</span> <span class="hljs-attr">v-slot:item.tag</span>=<span class="hljs-string" style="color: rgb(221, 136, 136);">"</span></span></span><span class="hljs-template-variable" style="color: rgb(221, 136, 136);">{ item }</span><span class="xml"><span class="hljs-tag"><span class="hljs-string" style="color: rgb(221, 136, 136);">"</span>&gt;</span>
      </span><span class="hljs-template-variable" style="color: rgb(221, 136, 136);"> getTags(item) </span><span class="xml">
    <span class="hljs-tag">&lt;/<span class="hljs-name" style="color: rgb(221, 136, 136); font-weight: 700;">template</span>&gt;</span>
    <span class="hljs-tag">&lt;<span class="hljs-name" style="color: rgb(221, 136, 136); font-weight: 700;">template</span> <span class="hljs-attr">v-slot:item.actions</span>=<span class="hljs-string" style="color: rgb(221, 136, 136);">"</span></span></span><span class="hljs-template-variable" style="color: rgb(221, 136, 136);">{ item }</span><span class="xml"><span class="hljs-tag"><span class="hljs-string" style="color: rgb(221, 136, 136);">"</span>&gt;</span>
      <span class="hljs-tag">&lt;<span class="hljs-name" style="color: rgb(221, 136, 136); font-weight: 700;">v-btn</span> <span class="hljs-attr">icon</span> @<span class="hljs-attr">click</span>=<span class="hljs-string" style="color: rgb(221, 136, 136);">"deleteObj(item)"</span>&gt;</span>
        <span class="hljs-tag">&lt;<span class="hljs-name" style="color: rgb(221, 136, 136); font-weight: 700;">v-icon</span>&gt;</span>mdi-delete<span class="hljs-tag">&lt;/<span class="hljs-name" style="color: rgb(221, 136, 136); font-weight: 700;">v-icon</span>&gt;</span>
      <span class="hljs-tag">&lt;/<span class="hljs-name" style="color: rgb(221, 136, 136); font-weight: 700;">v-btn</span>&gt;</span>
    <span class="hljs-tag">&lt;/<span class="hljs-name" style="color: rgb(221, 136, 136); font-weight: 700;">template</span>&gt;</span>
    <span class="hljs-tag">&lt;<span class="hljs-name" style="color: rgb(221, 136, 136); font-weight: 700;">template</span> <span class="hljs-attr">v-slot:no-data</span>&gt;</span>
      <span class="hljs-tag">&lt;<span class="hljs-name" style="color: rgb(221, 136, 136); font-weight: 700;">v-btn</span> <span class="hljs-attr">color</span>=<span class="hljs-string" style="color: rgb(221, 136, 136);">"primary"</span>&gt;</span> Reset <span class="hljs-tag">&lt;/<span class="hljs-name" style="color: rgb(221, 136, 136); font-weight: 700;">v-btn</span>&gt;</span>
    <span class="hljs-tag">&lt;/<span class="hljs-name" style="color: rgb(221, 136, 136); font-weight: 700;">template</span>&gt;</span>
  <span class="hljs-tag">&lt;/<span class="hljs-name" style="color: rgb(221, 136, 136); font-weight: 700;">v-data-table</span>&gt;</span>
<span class="hljs-tag">&lt;/<span class="hljs-name" style="color: rgb(221, 136, 136); font-weight: 700;">v-container</span>&gt;</span></span></pre>
            </div>
          </div>-->
        </div>
      </div>
    </div>

    <div :data-aos="fade" data-aos-mirror="false" class="page">
      <div v-bind:class="{ background: !isLight }">
        <video
          style="border-style: solid; border-color: #202020; border-width: 0.1px"
          class="body-video"
          :id="'video_milo_editor'"
          loop="true"
          autoplay="true"
          preload="auto"
          muted="muted"
          width="100%"
          height="auto"
        >
          <source src="contourui.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>

    <div :data-aos="fade" data-aos-mirror="false" class="page">
      <div v-bind:class="{ background: !isLight }">
        <div class="row">
          <div class="columnLeft">
            <!-- <p v-html="code"></p> -->
            <h1 v-bind:class="[isLight ? 'body-title-light' : 'body-title']">Sharing</h1>
            <!-- <v-divider
                v-bind:class="[isLight ? 'background-dark' : 'background-light']"
              style="margin-top: 10px; margin-bottom: 10px; width: 55%"
            ></v-divider> -->
            <p v-bind:class="[isLight ? 'body-text-light' : 'body-text']">
              One of the most important things we wanted to test is the ability to share
              the exhibits created. Ideally, we would do this through user accounts with
              shareable links. <br />For the sake of time, we have chosen not to set up a
              database, but to share the exhibitions by means of the Dropbox API.
            </p>
            <p v-bind:class="[isLight ? 'body-text-light' : 'body-text']">
              This API provides fast storage with well-documented features. When saving,
              the whole scene is serialized and stored as a Json file. This Json file can
              then be shared with a link or opened directly in the home screen.
            </p>

            <p v-bind:class="[isLight ? 'body-text-light' : 'body-text']">
              In the rework that is now taking place, this API will be replaced by Cloud
              Firestore.
            </p>
          </div>
          <div class="columnRight">
            <div class="section">
              <div class="section">
                <video
                  class="body-video"
                  style="margin-top: 4%"
                  :id="'video_milo_editor'"
                  loop="true"
                  autoplay="true"
                  preload="auto"
                  muted="muted"
                  width="100%"
                  height="auto"
                >
                  <source src="contour share.mp4" type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div :data-aos="fade" data-aos-mirror="false" class="page">
      <div v-bind:class="{ background: !isLight }">
        <div class="row">
          <div class="columnLeft">
            <!-- <p v-html="code"></p> -->
            <h1 v-bind:class="[isLight ? 'body-title-light' : 'body-title']">Impact</h1>
            <!-- <v-divider
                v-bind:class="[isLight ? 'background-dark' : 'background-light']"
              style="margin-top: 10px; margin-bottom: 10px; width: 55%"
            ></v-divider> -->
            <p v-bind:class="[isLight ? 'body-text-light' : 'body-text']">
              Both the test build and prototypes of Contour were received favourably by
              users. The core of Contour has been validated and we gathered a lot of
              feedback for future iterations. The experience garnered from this project is
              valuable, we built a new type of tool from the ground up in just a short
              timespan. This project taught me the power of co-creation. You can tackle a
              lot of complicated issues early on by working directly with the users.
            </p>
            <p v-bind:class="[isLight ? 'body-text-light' : 'body-text']"></p>
          </div>
        </div>
      </div>
    </div>

    <!-- third section-->
    <div :data-aos="fade" data-aos-mirror="false" class="page">
      <div v-bind:class="{ background: !isLight }">
        <div class="row">
          <div class="columnLeft">
            <h1 v-bind:class="[isLight ? 'body-title-light' : 'body-title']">
              Recognition
            </h1>
            <!-- <v-divider
                v-bind:class="[isLight ? 'background-dark' : 'background-light']"
              style="margin-top: 10px; margin-bottom: 10px; width: 28%"
            ></v-divider> -->
            <p
              v-bind:class="[isLight ? 'body-text-light' : 'body-text']"
              v-html="pagedata.recognition"
            ></p>
            <!-- <Description :description="pagedata.recognition"></Description> -->
          </div>
          <div class="columnRight">
            <div class="section">
              <div class="rounded-iframe videoWrapper">
                <div class="containerIframe">
                  <iframe
                    width="560"
                    height="315"
                    class="responsive-iframe"
                    src="https://www.youtube-nocookie.com/embed/aNj8AA6CuAU?controls=0"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>

                <!-- <iframe

                src="https://www.youtube-nocookie.com/embed/aNj8AA6CuAU?controls=0"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe> -->
              </div>
            </div>
            <!-- <div class="section">
              <Technologies :technologies="pagedata.tech"></Technologies>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VideoBanner from "@/components/VideoBanner";
import projects from "@/JS/Projects.js";
import Metadata from "@/components/Metadata";
import Technologies from "@/components/Technologies";
import Description from "@/components/Description";
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();

export default {
  name: "Contour",
  components: {
    VideoBanner,
    Metadata,
    Technologies,
    Description,
  },
  mounted() {
    window.scrollTo(0, 0);
    this.$forceUpdate();
    this.pagedata = projects[0];
  },
  methods: {},
  computed: {
    isLight() {
      let light = this.$store.state.viewMode === "light";
      if (localStorage.getItem("mode") === "light") light = true;
      else light = false;
      return light;
    },
    fade() {
      if (this.$store.state.mobile) return "";
      return "fade-in";
    },
  },
  data() {
    return {
      pagedata: { name: "", info: "", description: "", meta: "" },
      projectBackground:
        "During my final year of HKU I was approached by fellow student and good friend Jelle Hoogenberg. He told me about his past experience with designing a museum exhibition and the problems that he faced during that project. This led to a research project and we agreed to work together in order to solve these problems and so Contour was born. <br/><br/> This project won the HKU Award for Innovation!",
      tableHeaders: [
        "Creating visualisations",
        "Viewing visualisations",
        "Collaboration",
      ],
      tableHeadersMethods: [
        "Method",
        "visitor experience",
        "Presentable",
        "Fast to make & modify",
        "Collaboration possibilities",
      ],

      tableRowsMethods: [
        {
          method: "Maquettes",
          experience: "Good",
          presentable: "Excellent",
          fast: "Poor",
          collab: "Decent",
        },
        {
          method: "Floor plans",
          experience: "Poor",
          presentable: "Poor",
          fast: "Decent",
          collab: "Poor",
        },
        {
          method: "Sketches",
          experience: "Poor",
          presentable: "Poor",
          fast: "Good",
          collab: "Poor",
        },
        {
          method: "3D renders",
          experience: "Excellent",
          presentable: "Decent",
          fast: "Poor",
          collab: "Good",
        },
      ],
      tableRows: [
        {
          left: "3D software is difficult to learn and use",
          center:
            "	3D visualisations can’t be opened easily without a specific application",
          right: "Visualisations in 3D software can’t be shared easily",
        },
        {
          left: "Decently powerful computer required for 3D rendering",
          center: "Static visualizations give a limited view of the visitor experience",
          right: "No central place to give and review feedback on the design",
        },
        {
          left: "Big changes often require the creation of an entirely new visualization",
          center:
            "Some methods are not realistic enough to be presented to certain stakeholders",
          right: "Not practical to work with multiple people on one visualisation.",
        },
      ],
    };
  },
};
//   {left: "Creating visualisations", center: "Viewing visualisations", right: ""},
</script>

<style lang="scss">
.body-video {
  border-radius: 12px;
  width: 100%;
}

.problemTable {
  background: transparent;
  padding-right: 20px;
  padding-left: 20px;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
}

.rounded-iframe {
  border-radius: 12px;
  overflow: hidden;
  z-index: 1;
  height: 315px;
  width: 560px;
}

.background-dark {
  background-color: #202020;
}
.background-light {
  background-color: #f3f3f3;
}
.contourPage {
  padding: 0;
  margin: 0;
  position: absolute;
  width: 100%;
  opacity: 100;

  z-index: 1;
  color: white;
  overflow-x: hidden;
  -webkit-animation: animate 1s ease-in;
  animation: animate 1s ease-in;
}
@-webkit-keyframes animate {
  from {
    opacity: 0;
  }
  to {
    width: 100%;
  }
}
@keyframes animate {
}
.wrap {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 30vh;
}

.inner {
  align-self: center;
  width: 100%;
}

.content {
  position: relative;
  text-align: center;
  height: 100%;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 1250px;
}
/* Then style the iframe to fit in the container div with full height and width */
.responsive-iframe {
  height: 315px;
  width: 560px;
  width: 100%;
}

@media only screen and (max-width: 900px) {
  .responsive-iframe {
    height: 315px;
    width: 560px;
    width: 100%;
  }
  .wrap {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
  .container {
    position: relative;
    overflow: hidden;

    padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
  }

  /* Then style the iframe to fit in the container div with full height and width */
  .responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }

  .page:nth-child(1) {
    margin-top: -500px;
    background-color: red;
    display: none;
  }
  .page {
    width: 100vw;
    padding: 4px;
    margin-bottom: 12px;
  }
  .background {
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 12px;
  }

  .section {
    padding: 0 0 0 0;
    margin: 0 0 0 0;
  }
  .columnLeft {
    padding-bottom: 20px;
    padding-top: 24px;
  }

  .columnRight {
    padding-bottom: 32px;
    padding-top: 0;
  }
}
</style>
